<template>
  <div>
    <app-main>
      <template v-slot:main-top>
        <!-- 查询 -->
        <div class="query-header">
          <el-form label-width="80px" :model="form">
            <el-row>
               <el-col :span="6">
                <el-form-item label="包裹号">
                  <el-input v-model="form.package_no" class="input" placeholder="包裹号" clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="第三方号">
                  <el-input v-model="form.third_order_no" class="input" placeholder="第三方订单单号" clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="包裹状态">
                  <el-select class="input" placeholder="请选择" v-model="form.value" clearable @change="selectChange" @clear="selectClear">
                    <el-option v-for="(item, index) in options" :key="index" :value="item"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6">
                <el-form-item label="备注">
                  <el-input v-model="form.package_tips" class="input" placeholder="备注信息" clearable></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="创建时间">
                  <el-date-picker
                    class="input"
                    @change="dateChange"
                    v-model="form.createtime"
                    format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                    type="daterange"
                    range-separator="~"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item>
                  <div class="date-filter">
                    <span :class="{'active': spanIndex === index}" @click="dateClick(index)" v-for="(item, index) in dateSelection" :key="index">{{item}}</span>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item>
                  <el-button type="primary" size="small" @click="queryClick">查询</el-button>
                  <el-button size="small" @click="resetClick">重置</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </template>
      <template v-slot:main-content>
        <div>
          <!-- <data-statistics :statusNum="statusNum"/> -->
          <order-table @menuChange="menuChange" @curChange="curChange" :orderData="orderData" :activeIndex="activeIndex" @remove="remove"/>
        </div>
      </template>
    </app-main>
  </div>
</template>

<script>
import AppMain from 'components/baseStructure/AppMain'
// import DataStatistics from './childComps/DataStatistics'
import OrderTable from './childComps/OrderTable'
import { parceList, changeOrderStatus } from 'network/api'

export default {
  inject: ['mainReload'],
  components: {
    AppMain,
    // DataStatistics,
    OrderTable
  },
  data() {
    return {
      spanIndex: 0,
      options: ['全部', '待支付', '打单中', '已出单', '异常', '取消', '发货完成'],
      dateSelection: ['今天', '昨天', '最近7天', '最近30天'],
      form: {
        package_no: '', // 包裹号
        order_status: '', // 包裹状态
        third_order_no: '', // 三方单号
        value: '', //
        createtime: [], // 选择时间
        package_tips: '' // 备注
        // order: '' // 单号
      },
      orderData: {},
      platform: '', // 筛选平台参数
      activeIndex: '0', // 筛选状态参数
      currentPage: '1' // 当前页
      // statusNum: {}
    }
  },
  created() {
    // 初始化时间选择器
    const dayStar = new Date()
    const dayEnd = new Date()
    dayStar.setTime(dayStar.getTime())
    dayEnd.setTime(dayEnd.getTime() + 24 * 3600 * 1000)
    this.form.createtime = [dayStar.getFullYear() + '-' + (dayStar.getMonth() + 1) + '-' + dayStar.getDate(), dayEnd.getFullYear() + '-' + (dayEnd.getMonth() + 1) + '-' + dayEnd.getDate()]
    // 初始化订单列表
    this.getList()
    // 初始化子订单数量状态
    // this.getStatusNum({ createtime: this.form.createtime })
  },
  methods: {
    // 平台改变
    selectChange(val) {
      switch (val) {
        case '全部':
          this.form.order_status = '0'
          break
        case '待支付':
          this.form.order_status = '1'
          break
        case '打单中':
          this.form.order_status = '2'
          break
        case '已出单':
          this.form.order_status = '3'
          break
        case '异常':
          this.form.order_status = '4'
          break
        case '取消':
          this.form.order_status = '5'
          break
        case '发货完成':
          this.form.order_status = '6'
          break
      }
    },
    // 清除选择平台
    selectClear() {
      this.form.order_status = '0'
    },
    // 筛选订单列表请求
    getList() {
      parceList({
        package_no: this.form.package_no, // 包裹号
        third_order_no: this.form.third_order_no, // 包裹号
        createtime: this.form.createtime, // 时间
        order_status: this.form.order_status, // 包裹状态
        order_tips: this.form.package_tips, // 备注
        // platform: this.platform, // 平台
        page: this.currentPage // 当前页
      }).then(res => {
        this.orderData = res.data
      })
    },
    // 获取不同状态子订单数量
    // getStatusNum (params) {
    //   // eslint-disable-next-line no-undef
    //   statusNum(params).then(res => {
    //     this.statusNum = res.data
    //   })
    // },
    // menu改变
    menuChange(index) {
      this.activeIndex = index
      this.currentPage = '1'
      switch (index) {
        // 全部
        case '0':
          this.getList()
          break
        // 待支付
        case '1':
          this.getList()
          break
        // 打单中
        case '2':
          this.getList()
          break
        // 已出单
        case '3':
          this.getList()
          break
        // 异常
        case '4':
          this.getList()
          break
        // 取消
        case '5':
          this.getList()
          break
      }
    },
    // 分页改变
    curChange(val) {
      this.currentPage = val
      this.getList()
    },
    // 选择时间改变
    dateChange(val) {
      this.form.createtime = val
      this.spanIndex = false
      // 查询
      this.getList()
      // this.getStatusNum({ createtime: this.form.createtime })
    },
    // 日期点击
    dateClick(index) {
      this.spanIndex = index
      var dayStar = new Date()
      var dayEnd = new Date()
      switch (index) {
        // 今天
        case 0:
          dayStar.setTime(dayStar.getTime())
          dayEnd.setTime(dayEnd.getTime() + 24 * 3600 * 1000)
          this.form.createtime = [dayStar.getFullYear() + '-' + (dayStar.getMonth() + 1) + '-' + dayStar.getDate(), dayEnd.getFullYear() + '-' + (dayEnd.getMonth() + 1) + '-' + dayEnd.getDate()]
          this.getList()
          this.getStatusNum({ createtime: this.form.createtime })
          break
        // 昨天
        case 1:
          dayStar.setTime(dayStar.getTime() - 24 * 3600 * 1000)
          dayEnd.setTime(dayEnd.getTime())
          this.form.createtime = [dayStar.getFullYear() + '-' + (dayStar.getMonth() + 1) + '-' + dayStar.getDate(), dayEnd.getFullYear() + '-' + (dayEnd.getMonth() + 1) + '-' + dayEnd.getDate()]
          this.getList()
          this.getStatusNum({ createtime: this.form.createtime })
          break
        // 最近7天
        case 2:
          dayStar.setTime(dayStar.getTime() - 7 * 24 * 3600 * 1000)
          dayEnd.setTime(dayEnd.getTime() + 24 * 3600 * 1000)
          this.form.createtime = [dayStar.getFullYear() + '-' + (dayStar.getMonth() + 1) + '-' + dayStar.getDate(), dayEnd.getFullYear() + '-' + (dayEnd.getMonth() + 1) + '-' + dayEnd.getDate()]
          this.getList()
          this.getStatusNum({ createtime: this.form.createtime })
          break
        // 最近30天
        case 3:
          dayStar.setTime(dayStar.getTime() - 30 * 24 * 3600 * 1000)
          dayEnd.setTime(dayEnd.getTime() + 24 * 3600 * 1000)
          this.form.createtime = [dayStar.getFullYear() + '-' + (dayStar.getMonth() + 1) + '-' + dayStar.getDate(), dayEnd.getFullYear() + '-' + (dayEnd.getMonth() + 1) + '-' + dayEnd.getDate()]
          this.getList()
          this.getStatusNum({ createtime: this.form.createtime })
          break
      }
    },
    // 查询
    queryClick() {
      this.getList()
      // this.getStatusNum({ createtime: this.form.createtime })
    },
    // 重置
    resetClick() {
      this.mainReload()
    },
    // 取消订单
    remove(id) {
      changeOrderStatus({ id: id, order_status: '5' }).then(res => {
        this.getList()
        // this.getStatusNum({ createtime: this.form.createtime })
        this.$message({
          message: '成功',
          type: 'success'
        })
      })
    }
  }
}
</script>
<style scoped>
.input[data-v-e3bd284a]{
  width: 80% !important;
}
</style>
<style scoped src="styles/components/queryHeader.css">
</style>
